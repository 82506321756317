<template>
  <div class="my-pagination">
    <el-pagination 
      class="page-box" 
      @size-change="handleSizeChange" 
      @current-change="handleCurrentChange" 
      background 
      :current-page="childMsg.page" 
      :page-sizes="[20, 50, 100, 200]" 
      :page-size="childMsg.limit" 
      layout="prev, pager, next, jumper, sizes" 
      :total="childMsg.total"
    >
    </el-pagination>
    <div>
      <i class="el-icon-refresh page-refresh" @click="refreshTable"></i>
      <span class="left-pagination">
        <span v-if="childMsg.total">显示{{ childMsg.total ? ((childMsg.page - 1) * childMsg.limit + 1) : 0 }}到{{ childMsg.page * childMsg.limit > childMsg.total ? childMsg.total :  childMsg.page * childMsg.limit}},</span>
        共{{ childMsg.total }}条记录</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pagination',
  props: ['childMsg'],
  data() {
    return {
      pageparm: {
        page: this.childMsg.page,
        limit: this.childMsg.limit
      }
    }
  },
  created() {
  },
  methods: {
    handleSizeChange(val) {
      this.pageparm.limit = val
      this.$emit('refreshTable', this.pageparm)
    },
    handleCurrentChange(val) {
      this.pageparm.page = val
      this.$emit('refreshTable', this.pageparm)
    },
    refreshTable(){
      this.$emit('refreshTable', this.pageparm)
    }
  }
}
</script>

<style>
.my-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
}
.page-box .el-pagination {
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 15px 0;
  margin-right: calc(2% - 20px);
  background-color: #fff;
}
.left-pagination{
  font-weight: 400;
  color: #606266;
  letter-spacing: 1px;
  font-size: 13px;
}
.page-refresh{
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"my-dialog",attrs:{"title":_vm.title,"width":"900px","close-on-click-modal":false,"visible":_vm.closeFlag,"before-close":_vm.closeModel,"append-to-body":"","destroy-on-close":"","show-close":!_vm.loading,"center":""},on:{"update:visible":function($event){_vm.closeFlag=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"forms-form",attrs:{"element-loading-text":"保存中"}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.ruleForm,"size":"middle","label-width":"120px"}},_vm._l((_vm.ruleForm.returnListOfDeliveryList),function(item,index){return _c('div',{key:index},[_c('el-divider',{attrs:{"content-position":"center"}},[_vm._v(_vm._s(`${item.consigneeName} - ${item.productName} - ${item.createTime}`))]),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"购买重量：","prop":`returnListOfDeliveryList.${index}.practicaWeight`,"rules":[
              { required: true, message: '请输入购买重量', trigger: 'change'},
              {pattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/, message: '请输入数字', trigger: 'change'}
            ]}},[(_vm.editType == 'look')?_c('p',{staticClass:"view-cont"},[_vm._v(_vm._s((item.practicaWeight || '') + (item.weightUnit || '')))]):_c('el-input',{staticClass:"input-with-select",attrs:{"clearable":"","placeholder":"请输入购买重量","maxlength":"14","show-word-limit":""},on:{"input":function($event){return _vm.marketpracticaWeightInput(item)}},model:{value:(item.practicaWeight),callback:function ($$v) {_vm.$set(item, "practicaWeight", $$v)},expression:"item.practicaWeight"}},[_c('el-select',{attrs:{"slot":"append","placeholder":"请选择"},slot:"append",model:{value:(item.weightUnit),callback:function ($$v) {_vm.$set(item, "weightUnit", $$v)},expression:"item.weightUnit"}},_vm._l((_vm.selectObj.saleUnit),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"单价：","prop":`returnListOfDeliveryList.${index}.marketPrice`,"rules":[
              {pattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/, message: '请输入数字', trigger: 'change'}
            ]}},[(_vm.editType == 'look')?_c('p',{staticClass:"view-cont"},[_vm._v(_vm._s(item.marketPrice))]):_c('el-input',{attrs:{"clearable":"","placeholder":"请输入单价","maxlength":"14","show-word-limit":""},on:{"input":function($event){return _vm.marketPriceInput(item)}},model:{value:(item.marketPrice),callback:function ($$v) {_vm.$set(item, "marketPrice", $$v)},expression:"item.marketPrice"}})],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"金额：","prop":`returnListOfDeliveryList.${index}.practicalMarketPrice`,"rules":[
              {pattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/, message: '请输入数字', trigger: 'change'}
            ]}},[(_vm.editType == 'look')?_c('p',{staticClass:"view-cont"},[_vm._v(_vm._s(item.practicalMarketPrice))]):_c('el-input',{attrs:{"clearable":"","placeholder":"请输入金额","maxlength":"14","show-word-limit":""},on:{"input":function($event){return _vm.practicalMarketPriceInput(item)}},model:{value:(item.practicalMarketPrice),callback:function ($$v) {_vm.$set(item, "practicalMarketPrice", $$v)},expression:"item.practicalMarketPrice"}})],1)],1)],1)],1)}),0)],1),_c('div',{staticClass:"forms-btns",attrs:{"slot":"footer"},slot:"footer"},[(_vm.editType != 'look')?_c('el-button',{attrs:{"type":"primary","loading":_vm.loading,"size":"small"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("保存")]):_vm._e(),_c('el-button',{attrs:{"disabled":_vm.loading,"size":"small"},on:{"click":_vm.closeModel}},[_vm._v(" "+_vm._s(_vm.editType == 'look' ? '关闭' : '取消'))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
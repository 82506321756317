<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        size="middle"
        label-width="120px"
      >
      <div v-for="(item, index) in ruleForm.returnListOfDeliveryList" :key="index">
          <el-divider content-position="center">{{ `${item.consigneeName} - ${item.productName} - ${item.createTime}`}}</el-divider>
          <el-row>
            <el-col :span="24">
              <el-form-item label="购买重量：" :prop="`returnListOfDeliveryList.${index}.practicaWeight`" :rules="[
                { required: true, message: '请输入购买重量', trigger: 'change'},
                {pattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/, message: '请输入数字', trigger: 'change'}
              ]">
                <p v-if="editType == 'look'" class="view-cont">{{ (item.practicaWeight || '') + (item.weightUnit || '')}}</p>
                <el-input clearable @input="marketpracticaWeightInput(item)" v-else v-model="item.practicaWeight" placeholder="请输入购买重量" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="item.weightUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.saleUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="单价：" :prop="`returnListOfDeliveryList.${index}.marketPrice`" :rules="[
                {pattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/, message: '请输入数字', trigger: 'change'}
              ]">
                <p v-if="editType == 'look'" class="view-cont">{{ item.marketPrice }}</p>
                <el-input clearable @input="marketPriceInput(item)" v-else v-model="item.marketPrice" placeholder="请输入单价" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="金额：" :prop="`returnListOfDeliveryList.${index}.practicalMarketPrice`" :rules="[
                {pattern: /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/, message: '请输入数字', trigger: 'change'}
              ]">
                <p v-if="editType == 'look'" class="view-cont">{{ item.practicalMarketPrice }}</p>
                <el-input clearable @input="practicalMarketPriceInput(item)" v-else v-model="item.practicalMarketPrice" placeholder="请输入金额" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
export default {
  name: "deliveryEdit",
  components: {
  },
  props:{
  },
  data() {
    return {   
      closeFlag: false,
      loading: false,
      title: '',
      editType: 'add',
      ruleForm: {
        returnListOfDeliveryList: []
      },
      selectObj: {
        saleUnit: CONSTPARAMS.saleUnit,
      },
    };
  },
  created() {
	},
  mounted() {
  },
  methods: {
    marketpracticaWeightInput(item){
      let price = item.marketPrice,
        practicaWeight = item.practicaWeight
      if(isNaN(practicaWeight)) return
      item.practicalMarketPrice = utils.getFixedNumber(price * practicaWeight)
      this.$forceUpdate()
    },

    marketPriceInput(item){
      let price = item.marketPrice,
        practicaWeight = item.practicaWeight
      if(isNaN(price)) return
      item.practicalMarketPrice = utils.getFixedNumber(price * practicaWeight)
      this.$forceUpdate()
    },

    practicalMarketPriceInput(item){
      let practicalMarketPrice = item.practicalMarketPrice,
        practicaWeight = item.practicaWeight
      if(isNaN(practicalMarketPrice)) return
      if(!practicaWeight) return
      item.marketPrice = utils.getFixedNumber(practicalMarketPrice / practicaWeight)
      this.$forceUpdate()
    },

    async initData(title, editType, ruleForm){
      this.ruleForm.returnListOfDeliveryList = ruleForm
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitData()
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this,
        editType = this.editType,
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        }
      _this.loading = true
      let ways = editType == 'add' ? 'deliveryupdate' : 'deliveryupdate',
        target = [
        ..._this.ruleForm.returnListOfDeliveryList,
      ]
      
      request[ways]({
        returnListOfDeliveryList: target,
        userId: utils.getUserId()
      }).then((res) => {
        if(res.code == 200){
          _this.message(mesInfo[editType], "success")
          _this.closeModel()
          _this.$emit("getData", editType == 'add' ? true : false)
        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>



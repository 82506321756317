<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-search">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <div class="title-search-line pb5 bb">
            <el-form-item label="客户名称:">
              <el-input size="small" v-model="searchForm.consigneeName" placeholder="请输入客户名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="日期:">
              <el-date-picker
                class="my-date-range"
                v-model="timeRange"
                @change="timeChange"
                type="daterange"
                align="right"
                unlink-panels
                size="small"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="产品名称:">
              <el-input size="small" v-model="searchForm.productName" placeholder="请输入产品名称" clearable></el-input>
            </el-form-item>
          </div>
          <div class="title-search-line pt5">
            <el-form-item label="产地:">
              <el-input class="my-input-btn my-input-btn1" :disabled="true" placeholder="请选择" suffix-icon="el-icon-arrow-down" @click.native="chooseType('getpickarea')" v-model="searchForm.address"></el-input>
            </el-form-item>
            <el-form-item label="上游供应商:">
              <el-input size="small" v-model="searchForm.upstreamSupplierName" placeholder="请输入上游供应商" clearable></el-input>
            </el-form-item>
            <el-form-item label="生产者:">
              <el-input size="small" v-model="searchForm.producer" placeholder="请输入生产者" clearable></el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click="getData(true)">查询</el-button>
          </div>
        </el-form>
      </div>
      <div class="my-title-tab">
        <div class="my-delivery-text">配送清单批量生成合格证及打印步骤：1、下载模板。2、按模板做好当天的配送清单。3、已做好的配送清单批量导入。4、按每个客户批量打印合格证。</div>
        <div class="title-tab-btns">
          <el-upload
            class="upload-btn"
            list-type="text"
            ref="uploadFile"
            action
            accept=".xls,.xlsx"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="importData"
            :limit="1"
            >
            <el-button size="small" icon="el-icon-upload2" type="primary">批量导入</el-button>
          </el-upload>
          <el-button type="primary" size="small" icon="el-icon-printer" @click="mulsPrint">打印</el-button>
          <el-button type="primary" size="small" icon="el-icon-edit" @click="mulsEdit">修改</el-button>
          <el-button type="primary" size="small" icon="el-icon-delete" @click="mulsDel">删除</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download">导出</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="download2">下载模板</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="46">
        </el-table-column>
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column label="产品来源" align="center">
          <el-table-column
            prop="supplierName"
            label="来源主体"
            align="center"
            :show-overflow-tooltip="false">
          </el-table-column>
          <el-table-column
            prop="address"
            label="产地"
            align="center"
            :show-overflow-tooltip="false">
          </el-table-column>
          <el-table-column
            prop="producer"
            label="生产者"
            align="center"
            :show-overflow-tooltip="false">
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
      <pick-area ref="getpickarea" @getpickarea="getpickarea" sureBtnAlltime></pick-area>
    </div>
    <delivery-edit ref="editForm" @getData="getData"></delivery-edit>
    <print-list ref="printList"></print-list>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import deliveryEdit from './deliveryEdit.vue'
  import printList from './printList.vue'
  import pickArea from '@/views/common/pickArea.vue'
  import * as XLSX from "xlsx"
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'delevery',
    components: {
      pagination,
      breadcrumb,
      pickArea,
      printList,
      deliveryEdit
    },
    data() {
      let title = '配送清单批量打印合格证'
      return {
        timeRange: '',
        timeRange2: '',
        loadtext: '加载中',
        breadStaticData:['系统管理', '配送清单批量打印合格证（配送企业使用）'],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId(),
          productName: '',
          consigneeName: '',
          startTime: '',
          endTime: '',
          upstreamSupplierName: '',
          producer: '',
          province: '',
          provinceCode: '',
          city: '',
          cityCode: '',
          county: '',
          countyCode: '',
          town: '',
          townCode: '',
          village: '',
          villageCode: '',
          address: ''
        },
        urlName: 'findlistofdelivery',
        tableHead: [{
          prop: 'createTime',
          label: '日期'
        },{
          prop: 'consigneeName',
          label: '客户主体名称'
        },{
          prop: 'mobilePhoneNumber',
          label: '客户手机',
          width: 150
        },{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'practicaWeight',
          label: '重量',
          width: 120
        },{
          prop: 'weightUnit',
          label: '单位',
          width: 120
        },{
          prop: 'marketPrice',
          label: '单价'
        },{
          prop: 'practicalMarketPrice',
          label: '金额'
        },{
          prop: 'channelTypeName',
          label: '产品来源'
        }],
        pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        multipleSelection: [],
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      change1(val){
      },
      handleSelectionChange(val){
        this.multipleSelection = val
      },
      getpickarea(data){
        let address = ''
        data.forEach(cur => {
          if(cur.areaCode){
            address += cur.areaName
          }
        })
        this.searchForm.province = data[0] && data[0].areaName || ''
        this.searchForm.provinceCode = data[0] && data[0].areaCode || ''
        this.searchForm.city = data[1] && data[1].areaName || ''
        this.searchForm.cityCode = data[1] && data[1].areaCode || ''
        this.searchForm.county = data[2] && data[2].areaName || ''
        this.searchForm.countyCode = data[2] && data[2].areaCode || ''
        this.searchForm.town = data[3] && data[3].areaName || ''
        this.searchForm.townCode = data[3] && data[3].areaCode || ''
        this.searchForm.village = data[4] && data[4].areaName || ''
        this.searchForm.villageCode = data[4] && data[4].areaCode || ''
        this.searchForm.address = address || ''
      },
      chooseType(name){
        this.$refs[name].initData()
      },
      download2(){
        let arr = this.listData,
        tableData = [['序号', '产品名称', '产品生产批次', "次批开始生产日期", "日常农事日期",  "日常农事名称", "天气", "劳作人", "负责人"]] // 表格表头
        if(!arr || !arr.length){
          this.message('暂无数据，无法导出！', "warning")
          return
        }
        arr.forEach((item, index) => {
          let rowData = []
          rowData = [
            index + 1,
            item.productName,
            item.docNum,
            item.startProductionDate,
            item.farmingDate,
            item.managementName,
            item.weather,
            item.worker,
            item.head,
          ]
          tableData.push(rowData)
        })
        this.loading = true
        this.loadtext = '导出中'
        let workSheet = XLSX.utils.aoa_to_sheet(tableData);
        let bookNew = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(bookNew, workSheet, '数据') // 工作簿名称
        let name = this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
        XLSX.writeFile(bookNew, name) 
        this.loadtext = '加载中'
        this.loading = false
      },
      timeChange(val){
        let startTime = '',
          endTime = ''
        if(val){
          startTime = utils.dateFormat(val[0], 'yyyy-MM-dd')
          endTime = utils.dateFormat(val[1], 'yyyy-MM-dd')
        }
        this.searchForm.startTime = startTime
        this.searchForm.endTime = endTime
      },
      async initTable(){
        this.getData()
      },
      async add(){
        // let id = await utils.getNewId(),
        //   obj = {
        //   ...this.initForm,
        //   batchId: id
        // }
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add')
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data){
        let _this = this
        request.findfarmingmanagement({
          managementId: data.managementId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              imageUrl: result.imageUrl ? result.imageUrl.split(',') : [],
              videoUrl: result.videoUrl ? result.videoUrl.split(',') : [],
              startProductionDate: result.startProductionDate ? utils.dateFormat(result.startProductionDate, 'yyyy-MM-dd') : '',
              farmingDate: result.farmingDate ? utils.dateFormat(result.farmingDate, 'yyyy-MM-dd') : '',
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      importData(){
        let _this = this
        this.loading = true
        this.loadtext = '导入中'
        if (this.$refs.uploadFile.uploadFiles.length > 0) {
          let fileData = new FormData(),
            file = this.$refs.uploadFile.uploadFiles[0];
          fileData.append("bodyId", utils.getBodyId())
          fileData.append("file", file.raw)
          fileData.append("userId", utils.getUserId())
          request.deliveryImport(fileData).then(res => {
            _this.$refs.uploadFile.clearFiles()
            _this.loading = false
            _this.loadtext = '加载中'
            if(res.code == 200){
              _this.getData(true)
            }else{
              this.$alert(res.msg || '', '提示', {
                confirmButtonText: '确定',
                callback: () => {}
              })
            }
          }).catch(e => {
            _this.loading = false
            _this.loadtext = '加载中'
            _this.$notify({
              title: "失败",
              message: "导入失败",
              type: "error",
              duration: 2000,
            })
            console.log(e)
          })
        } else {
          _this.loading = false
          _this.loadtext = '加载中'
          this.message("请选择文件后再导入", "warning")
        }
      },
      download(){
        let _this = this,
          obj = {
          ..._this.searchForm
        }
        delete obj.page
        delete obj.limit
        this.getExportFile('deliveryexport', obj)
      },
      download2(){
        this.getExportFile('deliveryexport2')
      },
      getExportFile(url, obj){
        let _this = this
        request[url](obj).then(res => {
          let name = _this.pageName + ' ' + utils.dateFormat(new Date(), "yyyy-MM-dd HH时mm分ss秒") + '.xlsx'
          if(url == 'deliveryexport2'){
            name = _this.pageName + '模板.xlsx'
          }
          res && utils.download(res, name)
        }).catch(e => {
          _this.$notify({
            title: "失败",
            message: "导出失败",
            type: "error",
            duration: 2000,
          })
          console.log(e)
        })
      },
      mulsPrint(){
        let _this = this,
          obj = {
          ..._this.searchForm
        }
        delete obj.page
        delete obj.limit
        request.deliveryPrint(obj).then(res => {
          if(res.code == 200){
            this.$refs.printList.initData(res.data[0])
          }else{
            _this.message(res.msg || '', "error");
          }
        }).catch(e => {
          console.log(e)
        })
        // if (this.multipleSelection.length) {
        //   this.multipleSelection.forEach(cur => {
        //     obj.push({
        //       'bodyId': cur.bodyId || '',
        //       'createTime': cur.createTime || '',
        //       'consigneeName': cur.consigneeName || '',
        //       'mobilePhoneNumber': cur.mobilePhoneNumber || '',
        //       'productName': cur.productName || '',
        //       'practicaWeight': cur.practicaWeight || '',
        //       'weightUnit': cur.weightUnit || '',
        //       'marketPrice': cur.marketPrice || '',
        //       'practicalMarketPrice': cur.practicalMarketPrice || '',
        //       'bathGenCode': cur.bathGenCode || '',
        //       'startCode': cur.startCode || '',
        //       'endCode': cur.endCode || '',
        //       'certificateOfComplianceBathCode': cur.certificateOfComplianceBathCode || '',
        //     })
        //   })
        //   request.deliveryPrint({
        //     returnListOfDeliveryList: obj
        //   }).then(res => {
        //     if(res.code == 200){
        //       this.$refs.printList.initData(res.data[0])
        //     }else{
        //       _this.message(res.msg || '', "error");
        //     }
        //   }).catch(e => {
        //     console.log(e)
        //   })
        // }else{
        //   this.message('请选择记录', 'warning')
        // }
      },
      mulsEdit(){
        let obj = [], _this = this
        if (this.multipleSelection.length) {
          this.multipleSelection.forEach(cur => {
            obj.push({
              'bodyId': cur.bodyId || '',
              'transactionRecordId': cur.transactionRecordId || '',
              'certificateOfComplianceId': cur.certificateOfComplianceId || '',
              'practicaWeight': cur.practicaWeight || '',
              'weightUnit': cur.weightUnit || '',
              'marketPrice': cur.marketPrice || '',
              'practicalMarketPrice': cur.practicalMarketPrice || '',
              'createTime': cur.createTime || '',
              'consigneeName': cur.consigneeName || '',
              'productName': cur.productName || '',
            })
          })
          this.$refs.editForm.initData(`修改${this.pageName}`, 'edit', obj)
        }else{
          this.message('请选择记录', 'warning')
        }
      },
      mulsDel(){
        let obj = [], _this = this
        if (this.multipleSelection.length) {
          this.multipleSelection.forEach(cur => {
            obj.push({
              'bathGenCode': cur.bathGenCode || '',
              'transactionRecordId': cur.transactionRecordId || '',
              'certificateOfComplianceId': cur.certificateOfComplianceId || '',
            })
          })
          this.del(obj)
        }else{
          this.message('请选择记录', 'warning')
        }
      },
      del(deldata){
        let _this = this
        _this.$confirm(`不能恢复，是否真的删除？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.deliveryDel({
            deletePrintVoList: deldata
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = (document.querySelector('.page-table .my-title-tab')?.clientHeight || 0) 
          + (document.querySelector('.page-table .title-search')?.clientHeight || 0) 
          + (document.querySelector('.page-table .el-pagination')?.clientHeight || 0) 
          + CONSTPARAMS.staticHeight
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        let obj = {
          ..._this.searchForm
        }
        delete obj.address
        request[_this.urlName](obj).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                address: (item.province || '') + (item.city || '') + (item.county || '') + (item.town || '') + (item.village || ''),
                channelTypeName: utils.getConstToVal('channelType', item.channelType),
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
.my-input-btn ::v-deep .el-input__inner{
  height: 32px;
  line-height: 32px;
}
.title-tab-btns{
  display: flex;
  align-items: center;
}
.upload-btn{
  padding-right: 10px;
}
::v-deep .el-message-box__message{
  overflow: auto;
  max-height: 300px;
}
.title-search{
  padding: 5px 10px;
}
.my-title-tab{
  padding: 5px 0;
}
.my-delivery-text{
  font-size: 14px;
  color: red;
  flex-wrap: wrap;
  padding-bottom: 3px;
}

</style>
<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="770px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <div id="printArea" class="printArea" v-if="allListData && allListData.length">
      <div class="my-print-list" v-for="(item, index) in allListData" :key="index">
        <!-- height="calc(100vh - 50px)" -->
        <div class="print-title">
          <!-- <p> -->
            <span>
              <span class="print-title-desc">客户名称：{{ item.consigneeName || ''}}  </span>
              <span class="print-title-desc">客户手机：{{ item.mobilePhoneNumber || ''}}  </span>
              <span class="print-title-desc">合格证母码：{{ item.bathGenCode || ''}}  </span>
              <span class="print-title-desc">日期：{{ createTime || ''}} </span>
            </span>
            <!-- 扫条码批量入库 -->
            <my-image class="img" :src="item.qrcode" :preview-src-list="[item.qrcode]"></my-image>
          <!-- </p> -->
        </div>
        <table class="reference notranslate" v-if="item.listOfDeliveries && item.listOfDeliveries.length">
          <tr>
            <th width="60px">顺序号</th>
            <th>产品名称</th>
            <th>重量</th>
            <th>单位</th>
            <th>一物一码编码</th>
            <th width="130px">合格证追溯码</th>
          </tr>
          <tr v-for="(item2, index) in item.listOfDeliveries" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item2.productName }}</td>
            <td>{{ item2.practicaWeight }}</td>
            <td>{{ item2.weightUnit }}</td>
            <td>{{ item2.itemCode }}</td>
            <td><my-image class="img" :src="item2.qrcode" :preview-src-list="[item.qrcode2]"></my-image></td>
          </tr>
        </table>
        <table class="reference notranslate" v-else>
          <tr>
            <th>顺序号</th>
            <th>产品名称</th>
            <th>重量</th>
            <th>单位</th>
            <th width="130px">合格证追溯码</th>
          </tr>
          <tr>
            <td colspan="5">暂无数据</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="no-data" v-else>暂无数据</div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" size="small" v-print="printData">打印</el-button>
      <el-button @click="closeModel" size="small">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  let _t = null
  export default {
    name: 'printList',
    components: {
    },
    props:{
    },
    data() {
      let title = '打印列表'
      return {
        printData: {
          id: "printArea", 
          popTitle: CONSTPARAMS.sysName, 
          extraHead: "", 
          extraCss: "",
          clickMounted(e){
            // request.addPrint({
            //   ip: '',
            //   printerName: '',
            //   certificateOfComplianceIdList: _t.certificateOfComplianceIdList || []
            // }).then(res => {
            //   if(res.code == 200){
            //   }else{
            //     _t.message(res.msg || '', "error");
            //   }
            // }).catch(e => {
            //   console.log(e)
            // })
          }
        },
        closeFlag: false,
        loading: false,
        title: title,
        tableHead: [{
          prop: 'productName',
          label: '产品名称'
        },{
          prop: 'practicaWeight',
          label: '重量'
        },{
          prop: 'weightUnit',
          label: '单位'
        }],
        initForm: {
        },
        loadtext: '加载中',
        pageName: title,
        tableName: `${title}列表`,
        loading: false,
        tableHeight: null,
        allListData: [],
        createTime: '',
        certificateOfComplianceIdList: [],
        pageHeight: 1086
      }
    },
    created() {
    },
    mounted(){
      _t = this
    },
    methods: {
      initData(data){
        this.closeFlag = true
        this.allListData = data.consigneeNameList
        this.certificateOfComplianceIdList = data.certificateOfComplianceIdList || []
        this.createTime = data.createTime
        // this.$nextTick(() => {
        //   this.setPagings()
        // })
      },
      setPagings(){
        let nodeList = document.querySelectorAll('.my-print-list')
        for(let i = 0, len = nodeList.length; i < len; i++){
          let item = nodeList[i],
            pages = Math.ceil(item.clientHeight / this.pageHeight),
            allHeight = pages * this.pageHeight
          item.style.height = allHeight + 'px'
        }
      },
      closeModel() {
        this.closeFlag = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .my-print-list{
    page-break-after: always;
  }
  .print-title{
    display: flex;
    align-items: flex-end;
    font-size: 14px;
  }
  .print-title-desc{
    padding: 0 5px;
  }
  .img{
    width: 44px;
    height: 44px;
  }
  .printArea{
    padding-bottom: 10px;
  }

  ::v-deep .el-table__body-wrapper{
    border-bottom: 1px solid #EBEEF5;
  }
</style>